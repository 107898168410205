import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroWithOverlay from "../components/Hero/HeroWithOverlay";
import EstatePlanningGuide from "../components/Repeating/EstatePlanningGuide";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import FAQs from "../components/Repeating/FAQ";

const Page = ({ data }) => {
   const heroImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout>
         <SearchEngineOptimization
            title="Long Term Care Planning Attorney | Conscious Legal"
            description="For top long-term care planning in San Diego that is relationship-driven and not transaction-focused, call Conscious Legal. Schedule a consultation!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroWithOverlay
            image={heroImages}
            backgroundPosition="50% 0%"
            heading={["Long Term Care Planning"]}
            subtext="Long-term care planning involves developing a plan to pay for care."
            nextBackgroundColor="bg-primary-50"
         ></HeroWithOverlay>

         <section className="bg-primary-50 pb-20 md:pb-28">
            <div className="container">
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="About Alanna’s Mission" />
                  </div>
                  <div>
                     <h2>A Top Long Term Care Planning Attorney for Your Needs</h2>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        Long-term care planning involves paying for future care (should you require it) while protecting your assets. At Conscious
                        Legal, we understand the planning process can be overwhelming. When a loved one is facing long-term care needs, we will
                        explain viable options such as skilled nursing or in-home care. Alongside our estate planning services, our team will ease the
                        stressful nature of long-term care planning and provide you with the comfort of knowing you are protected.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="pt-24 md:pt-16 mb-20 md:mb-36" headingLevel="h2" />

         <section className="mb-16 md:mb-20">
            <div className="container">
               <header className="max-w-4xl mb-14 md:mb-18">
                  <h2>How It Works</h2>
                  <p>
                     Did you know that the average cost of long term care in California is over $10,000 per month, and that 7 out of 10 people will
                     eventually require some type of long term care in their lives? There are only three ways of paying for long term care needs:
                  </p>
               </header>
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center mb-20 md:mb-28">
                  <div className="order-2 md:order-1">
                     <div className="mb-12">
                        <span className="h-2 w-22 bg-primary-100 block mb-6 md:mb-8"></span>
                        <h3>Using Your Own Money.</h3>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           Sadly, this often means total indigence. The average cost of long term care in California is over $10,000 per month. Many
                           people spend their life savings in nursing homes or assisted living facilities, and then have nothing left for themselves
                           or to pass on to family members. Not a very good option!
                        </p>
                     </div>

                     <div className="mb-12">
                        <span className="h-2 w-22 bg-primary-100 block mb-6 md:mb-8"></span>
                        <h3>Long-term Care Insurance.</h3>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           This is a great option if available, but many people facing long term care needs do not have this type of insurance and
                           either cannot qualify or cannot afford the premiums. If you (or your parents) are a good candidate for long-term care
                           insurance, we will help you get the insurance you need. However, for some, long-term care insurance is simply not an
                           option.
                        </p>
                     </div>

                     <div>
                        <span className="h-2 w-22 bg-primary-100 block mb-6 md:mb-8"></span>
                        <h3>Need Based Government Benefits.</h3>
                        <p className="text-mobile-lg md:text-lg mb-0">
                           This leaves Veteran’s Benefits and the Medi-Cal program but not Medicare—Medicare DOESN’T pay. In California, Medi-Cal pays
                           for the costs of a nursing home, and may also cover other types of long term care. But in order to qualify for Medi-Cal,
                           applicants must be below $2,000 in savings.
                        </p>
                     </div>
                  </div>
                  <div className="order-1 md:order-2">
                     <GatsbyImage image={data.howItWorks.childImageSharp.gatsbyImageData} alt="How it Works" />
                  </div>
               </div>

               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div>
                     <GatsbyImage image={data.safeguardFuture.childImageSharp.gatsbyImageData} alt="Safeguarding Your Family’s Future" />
                  </div>
                  <div>
                     <h2>Safeguarding Your Family’s Future</h2>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        We can help your family ethically and legally convert “countable” savings to “non-countable” savings, so you can keep your
                        assets and still qualify for Medi-Cal. This is done not out of greed, but of necessity, so that your family is not left
                        indigent at the cost of long-term care. Without a plan, aging can rob you, and your family, of your life savings and a secure
                        future. We will help you protect your hard-earned savings and assets, as well as navigate a complex system, to assure that
                        you, or your loved one with long-term or special care needs, has the best care possible.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <Testimonials headingLevel="h2" />

         <EstatePlanningGuide className="py-16 md:py-28" headingLevel="h2" />

         <About headingLevel="h2" />

         <CallToAction headingLevel="h2" />

         <FAQs className="md:pt-16 pb-16 md:pb-24" headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Long term planning_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Long term planning_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/long-term-care/1.0 Hero desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "services/long-term-care/1.0 Hero long term - mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/long-term-care/2.0 intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks: file(relativePath: { eq: "services/long-term-care/3.0 how it works.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      safeguardFuture: file(relativePath: { eq: "services/long-term-care/4.0 long term.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      checkmark: file(relativePath: { eq: "global/custom-checkmark.svg" }) {
         publicURL
      }
   }
`;
export default Page;
